import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'
import EmployIcon from 'img/employees.inline.svg'

interface AccordeonProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Accordeon
}

const Title = styled.h2`
  font-size: 25px;
  color: ${(props) => props.theme.color?.secondary};
`

const BoxContent = styled(motion.div)`
  overflow: hidden;
  & p {
    color: ${(props) => props.theme.color.dark};
  }
`

const BoxWrapper = styled.div`
  background-color: #ffffff;
  color: #ff8300;
  border-radius: 25px;
  box-shadow: 0 0 20px rgb(0 0 0 / 30%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
`

// @ts-ignore
const StyledEmployIcon = styled(EmployIcon)`
  width: 235px;
  height: auto;

  @media (max-width: 991px) {
    width: 150px;
  }
`

const Content = styled(ParseContent)`
  & p {
    @media (max-width: 991px) {
      font-size: 14px !important;
      line-height: 28px !important;
    }
  }
`

const TitleWrapper = styled.div`
  & svg {
    width: 20px;
    height: 20px;

    & path {
      fill: #ff8300;
    }
  }
`

const Accordeon: React.FC<AccordeonProps> = ({ fields }) => {
  const [isOpen, setOpen] = useState(0)

  return (
    <section className="mb-lg-5 mb-2">
      <div className="container py-lg-5 py-3">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            {fields?.accordeonItem?.map((item, index) => (
              <Box
                isOpen={isOpen === index}
                setOpen={setOpen}
                index={index}
                className="p-4 mb-4"
                fields={item}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

// @ts-ignore
const Box = ({ isOpen, setOpen, index, className, fields }) => (
  <BoxWrapper className={`${className ? `${className}` : ``}`}>
    <motion.div
      className="d-flex justify-content-between align-items-center"
      onClick={() => setOpen(index)}
    >
      <div
        className={`d-flex ${
          isOpen ? 'align-items-start' : 'align-items-center'
        }`}
      >
        <StyledEmployIcon />
        <div className="ml-3">
          <TitleWrapper className="d-flex align-items-center justify-content-between pr-5">
            <Title className="ms-4">{fields.name}</Title>
            <svg
              className="chevron"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              width="512"
              height="512"
              viewBox="0 0 444.819 444.819"
              xmlSpace="preserve"
            >
              <g>
                <g xmlns="http://www.w3.org/2000/svg">
                  <path d="M434.252,114.203l-21.409-21.416c-7.419-7.04-16.084-10.561-25.975-10.561c-10.095,0-18.657,3.521-25.7,10.561   L222.41,231.549L83.653,92.791c-7.042-7.04-15.606-10.561-25.697-10.561c-9.896,0-18.559,3.521-25.979,10.561l-21.128,21.416   C3.615,121.436,0,130.099,0,140.188c0,10.277,3.619,18.842,10.848,25.693l185.864,185.865c6.855,7.23,15.416,10.848,25.697,10.848   c10.088,0,18.75-3.617,25.977-10.848l185.865-185.865c7.043-7.044,10.567-15.608,10.567-25.693   C444.819,130.287,441.295,121.629,434.252,114.203z" />
                </g>
              </g>
            </svg>
          </TitleWrapper>
          <BoxContent
            initial={{ height: '0px' }}
            animate={isOpen ? { height: 'auto' } : { height: '0px' }}
            transition={{ type: 'spring', damping: 20 }}
            className="ms-4"
          >
            <Content content={fields.description} />
          </BoxContent>
        </div>
      </div>
    </motion.div>
  </BoxWrapper>
)

export default Accordeon
